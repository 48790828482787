<template>
  <div class="help-article-preview">
    <img :src="article.preview_image.permalink" class="help-article-preview__image">

    <div class="help-article-preview__body">
      <h3 class="help-article-preview__title">
        {{ article.title }}
      </h3>
      <h4 class="help-article-preview__intro">
        {{ article.preview }}
      </h4>

      <a :href="article.permalink" class="help-article-preview__link" target="_blank">
        {{ $t('global.actions.read') }}
      </a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.help-article-preview {
  width: 25rem;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;

  &__image {
    border-radius: 12px;
    max-width: 100%;
    height: auto;
  }

  &__body {
    padding: 1rem;
  }

  &__title {
    text-align: center;
    margin-bottom: 0.75rem;
  }
  &__intro {
    font-size: 1rem;
    text-align: center;
    color: var(--grey-color);
    margin-bottom: 0.75rem;
  }
  &__link {
    color: #ff2d21;
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    display: block;

    &:hover {
      color: #ff2d21;
    }
  }
}
</style>
