<template>
  <t-page>
    <h3 slot="header">
      {{ $t('pages.help.heading') }}
    </h3>
    <t-grid>
      <t-grid-row>
        <t-grid-cell :size="6">
          <t-card>
            <t-grid>
              <t-grid-row>
                <t-grid-cell :size="8">
                  <h2>{{ $t('pages.help.getting_started.heading') }}</h2>
                  <p>
                    {{ $t('pages.help.getting_started.lead') }}
                  </p>
                </t-grid-cell>
                <t-grid-cell :size="4" style="display: flex; justify-content: flex-end">
                  <t-icon :icon="['far', 'question-circle']" boxed />
                </t-grid-cell>
              </t-grid-row>
            </t-grid>
          </t-card>
        </t-grid-cell>
        <t-grid-cell :size="6">
          <t-card>
            <t-grid>
              <t-grid-row>
                <t-grid-cell :size="8">
                  <h2>{{ $t('pages.help.support.heading') }}</h2>
                  <p>
                    {{ $t('pages.help.support.lead') }}
                  </p>
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  <span>{{ $t('fields.support_email') }}:
                    <a :href="`mailto:${tenant.tenant.meta.support_email}`">{{
                      tenant.tenant.meta.support_email
                    }}</a></span>
                </t-grid-cell>
                <t-grid-cell :size="4" style="display: flex; justify-content: flex-end">
                  <t-icon icon="phone" boxed />
                </t-grid-cell>
              </t-grid-row>
            </t-grid>
          </t-card>
        </t-grid-cell>
      </t-grid-row>

      <t-grid-row v-if="!$fetchState.pending">
        <div class="instructor-video">
          <iframe
            src="https://www.youtube.com/embed/kXNvEk1rSOY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />

          <div class="instructor-video__body">
            <h3>{{ $t('pages.help.training_video.heading') }}</h3>
            <h6 class="text--grey">
              {{ $t('pages.help.training_video.lead') }}
            </h6>
          </div>
        </div>
        <HelpArticlePreview
          v-for="article in validArticles"
          :key="article.slug"
          :article="article"
        />
      </t-grid-row>
      <TLoading v-else />
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import HelpArticlePreview from '~/components/Help/HelpArticlePreview';
import TLoading from '~/components/TLoading';

export default Vue.extend({
  components: { HelpArticlePreview, TLoading },

  data: () => ({
    articles: [],
  }),

  async fetch() {
    const { slug } = this.tenant.tenant;
    const { HELP_ARTICLES_API_URL } = this.$config.public;

    const [platformArticles, tenantArticles] = await Promise.all([
      this.$axios.get(
        `${HELP_ARTICLES_API_URL}/api/collections/help-articles/entries?site=default&filter[help-group:contains]=platform&sort=order`,
      ),
      this.$axios.get(
        `${HELP_ARTICLES_API_URL}/api/collections/help-articles/entries?site=default&filter[help-group:contains]=${slug}&sort=order`,
      ),
    ]);

    this.articles = [].concat(platformArticles.data.data, tenantArticles.data.data);
  },

  computed: {
    ...mapState(['tenant']),

    validArticles() {
      return this.articles.filter(article => article.preview_image);
    },
  },
});
</script>

<style lang="scss">
.instructor-video {
  width: 44rem;
  overflow: hidden;
  border-radius: 12px;
  background-color: #fff;

  iframe {
    width: 100%;
    height: 25rem;
  }

  &__body {
    padding: 1rem;
  }
}
</style>
